
			[data-rs-theme="darkmatter"] {
					--rs-font-family-title: var(--dm-font-family), sans-serif;
--rs-font-family-body: var(--dm-font-family), sans-serif;
--rs-font-family-mono: var(--dm-font-family-mono), monospace;
--rs-font-weight-regular: 400;
--rs-font-weight-medium: 500;
--rs-font-weight-semibold: 600;
--rs-font-weight-bold: 640;
--rs-font-weight-heavy: 800;
--rs-font-weight-black: 900;
--rs-font-size-title-1: 32px;
--rs-line-height-title-1: 40px;
--rs-font-family-title-1: var(--rs-font-family-body);
--rs-font-weight-title-1: var(--rs-font-weight-bold);
--rs-font-size-title-2: 24px;
--rs-line-height-title-2: 32px;
--rs-font-family-title-2: var(--rs-font-family-body);
--rs-font-weight-title-2: var(--rs-font-weight-bold);
--rs-font-size-title-3: 20px;
--rs-line-height-title-3: 28px;
--rs-font-family-title-3: var(--rs-font-family-body);
--rs-font-weight-title-3: var(--rs-font-weight-bold);
--rs-font-size-title-4: 16px;
--rs-line-height-title-4: 24px;
--rs-font-family-title-4: var(--rs-font-family-body);
--rs-font-weight-title-4: var(--rs-font-weight-bold);
--rs-font-size-title-5: 14px;
--rs-line-height-title-5: 20px;
--rs-font-family-title-5: var(--rs-font-family-body);
--rs-font-weight-title-5: var(--rs-font-weight-bold);
--rs-font-size-title-6: 12px;
--rs-line-height-title-6: 16px;
--rs-font-family-title-6: var(--rs-font-family-body);
--rs-font-weight-title-6: var(--rs-font-weight-bold);
--rs-font-size-featured-1: 32px;
--rs-line-height-featured-1: 40px;
--rs-font-family-featured-1: var(--rs-font-family-body);
--rs-font-size-featured-2: 24px;
--rs-line-height-featured-2: 32px;
--rs-font-family-featured-2: var(--rs-font-family-body);
--rs-font-size-featured-3: 20px;
--rs-line-height-featured-3: 28px;
--rs-font-family-featured-3: var(--rs-font-family-body);
--rs-font-size-body-1: 16px;
--rs-line-height-body-1: 24px;
--rs-font-family-body-1: var(--rs-font-family-body);
--rs-font-weight-body-1: var(--rs-font-weight-regular);
--rs-font-size-body-2: 14px;
--rs-line-height-body-2: 20px;
--rs-font-family-body-2: var(--rs-font-family-body);
--rs-font-weight-body-2: var(--rs-font-weight-regular);
--rs-font-size-body-3: 12px;
--rs-line-height-body-3: 16px;
--rs-font-family-body-3: var(--rs-font-family-body);
--rs-font-weight-body-3: var(--rs-font-weight-regular);
--rs-font-size-caption-1: 12px;
--rs-line-height-caption-1: 16px;
--rs-font-family-caption-1: var(--rs-font-family-body);
--rs-font-size-caption-2: 10px;
--rs-line-height-caption-2: 12px;
--rs-font-family-caption-2: var(--rs-font-family-body);
--rs-font-size-docs-title-1: 28px;
--rs-line-height-docs-title-1: 36px;
--rs-font-family-docs-title-1: var(--rs-font-family-body);
--rs-font-weight-docs-title-1: var(--rs-font-weight-bold);
--rs-font-size-docs-title-2: 24px;
--rs-line-height-docs-title-2: 32px;
--rs-font-family-docs-title-2: var(--rs-font-family-body);
--rs-font-weight-docs-title-2: var(--rs-font-weight-bold);
--rs-font-size-docs-title-3: 20px;
--rs-line-height-docs-title-3: 28px;
--rs-font-family-docs-title-3: var(--rs-font-family-body);
--rs-font-weight-docs-title-3: var(--rs-font-weight-bold);
--rs-font-size-docs-title-4: 18px;
--rs-line-height-docs-title-4: 24px;
--rs-font-family-docs-title-4: var(--rs-font-family-body);
--rs-font-weight-docs-title-4: var(--rs-font-weight-bold);
--rs-font-size-docs-title-5: 16px;
--rs-line-height-docs-title-5: 20px;
--rs-font-family-docs-title-5: var(--rs-font-family-body);
--rs-font-weight-docs-title-5: var(--rs-font-weight-bold);
--rs-font-size-docs-quote: 18px;
--rs-line-height-docs-quote: 28px;
--rs-font-family-docs-quote: var(--rs-font-family-body);
--rs-font-weight-docs-quote: var(--rs-font-weight-regular);
--rs-font-size-docs-title-description: 18px;
--rs-line-height-docs-title-description: 24px;
--rs-font-family-docs-title-description: var(--rs-font-family-body);
--rs-font-weight-docs-title-description: var(--rs-font-weight-regular);
--rs-font-size-docs-body: 16px;
--rs-line-height-docs-body: 24px;
--rs-font-family-docs-body: var(--rs-font-family-body);
--rs-font-weight-docs-body: var(--rs-font-weight-regular);
--rs-font-size-docs-body-medium: 14px;
--rs-line-height-docs-body-medium: 20px;
--rs-font-family-docs-body-medium: var(--rs-font-family-body);
--rs-font-weight-docs-body-medium: var(--rs-font-weight-regular);
--rs-font-size-docs-body-small: 12px;
--rs-line-height-docs-body-small: 16px;
--rs-font-family-docs-body-small: var(--rs-font-family-body);
--rs-font-weight-docs-body-small: var(--rs-font-weight-regular);
--rs-unit-base: 4px;
--rs-unit-radius-small: 3px;
--rs-unit-radius-medium: 7px;
--rs-unit-radius-large: 12px;
--rs-unit-radius: 5px;
--rs-unit-x1: 4px;
--rs-unit-x2: 8px;
--rs-unit-x3: 12px;
--rs-unit-x4: 16px;
--rs-unit-x5: 20px;
--rs-unit-x6: 24px;
--rs-unit-x7: 28px;
--rs-unit-x8: 32px;
--rs-unit-x9: 36px;
--rs-unit-x10: 40px;
--rs-color-background-disabled-faded: #141A2B05;
--rs-color-border-disabled: #DFE2EA;
--rs-color-white: #FFFFFF;
--rs-color-black: #09122A;
--rs-color-border-decorative: #14171F0F;
--rs-color-outline-primary: #004EE1;
--rs-color-on-background-primary: #FFFFFF;
--rs-color-on-background-critical: #FFFFFF;
--rs-color-on-background-positive: #FFFFFF;
--rs-color-rgb-background-disabled-faded: 20, 26, 43;
--rs-color-rgb-border-disabled: 223, 226, 234;
--rs-color-rgb-white: 255, 255, 255;
--rs-color-rgb-black: 9, 18, 42;
--rs-color-on-background-upgrade: #FFFFFF;
--rs-color-rgb-border-decorative: 20, 23, 31;
--rs-duration-fast: 200ms;
--rs-duration-medium: 300ms;
--rs-duration-slow: 400ms;
--rs-easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
--rs-easing-accelerate: cubic-bezier(0.4, 0, 1, 1);
--rs-easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
--rs-shadow-raised: 0px 1px 3px -3px rgba(9, 18, 42, 0.1), 0px 1px 3px -1px rgba(9, 18, 42, 0.06);
--rs-shadow-overlay: 0px 5px 10px 0px rgba(9, 18, 42, 0.05), 0px 15px 25px 0px rgba(9, 18, 42, 0.07);
			}
		
			[data-rs-theme="darkmatter"][data-rs-color-mode="light"] {
					--rs-color-background-primary: #104fc6;
--rs-color-background-primary-faded: #75b9ff1f;
--rs-color-background-primary-highlighted: #0038ae;
--rs-color-border-primary: #104fc6;
--rs-color-border-primary-faded: #5699ff4d;
--rs-color-foreground-primary: #1a58d0;
--rs-color-background-critical: #d41433;
--rs-color-background-critical-faded: #ff787c1f;
--rs-color-background-critical-highlighted: #bb001e;
--rs-color-border-critical: #d41433;
--rs-color-border-critical-faded: #ff555e4d;
--rs-color-foreground-critical: #c00022;
--rs-color-background-positive: #118850;
--rs-color-background-positive-faded: #71d7991f;
--rs-color-background-positive-highlighted: #00733d;
--rs-color-border-positive: #118850;
--rs-color-border-positive-faded: #4fb67b4d;
--rs-color-foreground-positive: #007841;
--rs-color-background-neutral: #6061620f;
--rs-color-background-neutral-faded: #60616208;
--rs-color-background-neutral-highlighted: #60616217;
--rs-color-border-neutral: #00000026;
--rs-color-border-neutral-faded: #00000013;
--rs-color-foreground-neutral: #000001;
--rs-color-foreground-neutral-faded: #66728a;
--rs-color-background-disabled: #6061620f;
--rs-color-foreground-disabled: #c6ccda;
--rs-color-background-elevation-base: #ffffff;
--rs-color-background-elevation-raised: #feffff;
--rs-color-background-elevation-overlay: #feffff;
--rs-color-background-page: #ffffff;
--rs-color-background-page-faded: #f8f9fb;
--rs-color-background-upgrade: #874ce6;
--rs-color-background-upgrade-highlighted: #7333ce;
--rs-color-background-upgrade-faded: #cd99ff1f;
--rs-color-background-warning: #cc4d00;
--rs-color-background-warning-highlighted: #b43600;
--rs-color-background-warning-faded: #ff935b1f;
--rs-color-background-blur: #ffffffeb;
--rs-color-background-blur-neutral-faded: #f8f9fbeb;
--rs-color-background-blur-neutral-highlighted: #f1f2f4eb;
--rs-color-foreground-primary-faded: #406ec5;
--rs-color-foreground-critical-faded: #bf3d43;
--rs-color-foreground-positive-faded: #48805d;
--rs-color-foreground-upgrade: #7434cf;
--rs-color-foreground-upgrade-faded: #7d56c8;
--rs-color-foreground-warning: #af3100;
--rs-color-foreground-warning-faded: #ac5530;
--rs-color-foreground-neutral-placeholder: #8390a9;
--rs-color-border-upgrade: #874ce6;
--rs-color-border-upgrade-faded: #ae78ff4d;
--rs-color-border-warning: #cc4d00;
--rs-color-border-warning-faded: #f572394d;
--rs-color-border-focus: #104fc6;
--rs-color-border-focus-faded: #5699ff4d;
--rs-color-background-decorative-blue: #75b9ff1f;
--rs-color-foreground-decorative-blue: #1a58d0;
--rs-color-border-decorative-blue: #5699ff4d;
--rs-color-background-decorative-cyan: #75cbea1f;
--rs-color-foreground-decorative-cyan: #006e8a;
--rs-color-border-decorative-cyan: #55abc94d;
--rs-color-background-decorative-fuchsia: #ff7aff1f;
--rs-color-foreground-decorative-fuchsia: #9f00b3;
--rs-color-border-decorative-fuchsia: #e258f64d;
--rs-color-background-decorative-green: #71d7991f;
--rs-color-foreground-decorative-green: #007841;
--rs-color-border-decorative-green: #4fb67b4d;
--rs-color-background-decorative-grey: #bdbebe1f;
--rs-color-foreground-decorative-grey: #636363;
--rs-color-border-decorative-grey: #9e9e9e4d;
--rs-color-background-decorative-light-grey: #b3bddc1f;
--rs-color-foreground-decorative-light-grey: #59627e;
--rs-color-border-decorative-light-grey: #949dbb4d;
--rs-color-background-decorative-orange: #ff935b1f;
--rs-color-foreground-decorative-orange: #af3100;
--rs-color-border-decorative-orange: #f572394d;
--rs-color-background-decorative-pink: #ff78b81f;
--rs-color-foreground-decorative-pink: #b8005e;
--rs-color-border-decorative-pink: #ff56994d;
--rs-color-background-decorative-purple: #cd99ff1f;
--rs-color-foreground-decorative-purple: #7434cf;
--rs-color-border-decorative-purple: #ae78ff4d;
--rs-color-background-decorative-red: #ff787c1f;
--rs-color-foreground-decorative-red: #c00022;
--rs-color-border-decorative-red: #ff555e4d;
--rs-color-background-decorative-teal: #77d1c61f;
--rs-color-foreground-decorative-teal: #00736a;
--rs-color-border-decorative-teal: #56b0a64d;
--rs-color-background-decorative-yellow: #f2ae641f;
--rs-color-foreground-decorative-yellow: #905200;
--rs-color-border-decorative-yellow: #d18e444d;
--rs-color-rgb-background-primary: 16, 79, 198;
--rs-color-rgb-background-primary-faded: 117, 185, 255;
--rs-color-rgb-background-primary-highlighted: 0, 56, 174;
--rs-color-rgb-border-primary: 16, 79, 198;
--rs-color-rgb-border-primary-faded: 86, 153, 255;
--rs-color-rgb-background-critical: 212, 20, 51;
--rs-color-rgb-background-critical-faded: 255, 120, 124;
--rs-color-rgb-background-critical-highlighted: 187, 0, 30;
--rs-color-rgb-border-critical: 212, 20, 51;
--rs-color-rgb-border-critical-faded: 255, 85, 94;
--rs-color-rgb-background-positive: 17, 136, 80;
--rs-color-rgb-background-positive-faded: 113, 215, 153;
--rs-color-rgb-background-positive-highlighted: 0, 115, 61;
--rs-color-rgb-border-positive: 17, 136, 80;
--rs-color-rgb-border-positive-faded: 79, 182, 123;
--rs-color-on-background-neutral: #09122A;
--rs-color-rgb-background-neutral: 96, 97, 98;
--rs-color-rgb-background-neutral-faded: 96, 97, 98;
--rs-color-rgb-background-neutral-highlighted: 96, 97, 98;
--rs-color-rgb-border-neutral: 0, 0, 0;
--rs-color-rgb-border-neutral-faded: 0, 0, 0;
--rs-color-rgb-background-disabled: 96, 97, 98;
--rs-color-rgb-background-elevation-base: 255, 255, 255;
--rs-color-rgb-background-elevation-raised: 254, 255, 255;
--rs-color-rgb-background-elevation-overlay: 254, 255, 255;
--rs-color-rgb-background-page: 255, 255, 255;
--rs-color-rgb-background-page-faded: 248, 249, 251;
--rs-color-rgb-background-upgrade: 135, 76, 230;
--rs-color-rgb-background-upgrade-highlighted: 115, 51, 206;
--rs-color-rgb-background-upgrade-faded: 205, 153, 255;
--rs-color-rgb-background-warning: 204, 77, 0;
--rs-color-rgb-background-warning-highlighted: 180, 54, 0;
--rs-color-rgb-background-warning-faded: 255, 147, 91;
--rs-color-rgb-background-blur: 255, 255, 255;
--rs-color-rgb-background-blur-neutral-faded: 248, 249, 251;
--rs-color-rgb-background-blur-neutral-highlighted: 241, 242, 244;
--rs-color-rgb-border-upgrade: 135, 76, 230;
--rs-color-rgb-border-upgrade-faded: 174, 120, 255;
--rs-color-rgb-border-warning: 204, 77, 0;
--rs-color-rgb-border-warning-faded: 245, 114, 57;
--rs-color-rgb-border-focus: 16, 79, 198;
--rs-color-rgb-border-focus-faded: 86, 153, 255;
--rs-color-rgb-background-decorative-blue: 117, 185, 255;
--rs-color-rgb-border-decorative-blue: 86, 153, 255;
--rs-color-rgb-background-decorative-cyan: 117, 203, 234;
--rs-color-rgb-border-decorative-cyan: 85, 171, 201;
--rs-color-rgb-background-decorative-fuchsia: 255, 122, 255;
--rs-color-rgb-border-decorative-fuchsia: 226, 88, 246;
--rs-color-rgb-background-decorative-green: 113, 215, 153;
--rs-color-rgb-border-decorative-green: 79, 182, 123;
--rs-color-rgb-background-decorative-grey: 189, 190, 190;
--rs-color-rgb-border-decorative-grey: 158, 158, 158;
--rs-color-rgb-background-decorative-light-grey: 179, 189, 220;
--rs-color-rgb-border-decorative-light-grey: 148, 157, 187;
--rs-color-rgb-background-decorative-orange: 255, 147, 91;
--rs-color-rgb-border-decorative-orange: 245, 114, 57;
--rs-color-rgb-background-decorative-pink: 255, 120, 184;
--rs-color-rgb-border-decorative-pink: 255, 86, 153;
--rs-color-rgb-background-decorative-purple: 205, 153, 255;
--rs-color-rgb-border-decorative-purple: 174, 120, 255;
--rs-color-rgb-background-decorative-red: 255, 120, 124;
--rs-color-rgb-border-decorative-red: 255, 85, 94;
--rs-color-rgb-background-decorative-teal: 119, 209, 198;
--rs-color-rgb-border-decorative-teal: 86, 176, 166;
--rs-color-rgb-background-decorative-yellow: 242, 174, 100;
--rs-color-rgb-border-decorative-yellow: 209, 142, 68;
			}
		
			[data-rs-theme="darkmatter"][data-rs-color-mode="dark"] {
					--rs-color-background-primary: #104fc6;
--rs-color-background-primary-faded: #5699ff1f;
--rs-color-background-primary-highlighted: #0038ae;
--rs-color-border-primary: #104fc6;
--rs-color-border-primary-faded: #3978f34d;
--rs-color-foreground-primary: #75b9ff;
--rs-color-background-critical: #d41433;
--rs-color-background-critical-faded: #ff555e1f;
--rs-color-background-critical-highlighted: #bb001e;
--rs-color-border-critical: #d41433;
--rs-color-border-critical-faded: #e42d404d;
--rs-color-foreground-critical: #ff787c;
--rs-color-background-positive: #118850;
--rs-color-background-positive-faded: #4fb67b1f;
--rs-color-background-positive-highlighted: #00733d;
--rs-color-border-positive: #118850;
--rs-color-border-positive-faded: #2a975e4d;
--rs-color-foreground-positive: #71d799;
--rs-color-background-neutral: #91a0c013;
--rs-color-background-neutral-faded: #91a0c00b;
--rs-color-background-neutral-highlighted: #91a0c01b;
--rs-color-border-neutral: #efffff26;
--rs-color-border-neutral-faded: #efffff13;
--rs-color-foreground-neutral: #f2ffff;
--rs-color-foreground-neutral-faded: #828ea7;
--rs-color-background-disabled: #91a0c013;
--rs-color-foreground-disabled: #3f4962;
--rs-color-background-elevation-base: #0c162e;
--rs-color-background-elevation-raised: #101b33;
--rs-color-background-elevation-overlay: #152038;
--rs-color-background-page: #0c162e;
--rs-color-background-page-faded: #081129;
--rs-color-background-upgrade: #874ce6;
--rs-color-background-upgrade-highlighted: #7333ce;
--rs-color-background-upgrade-faded: #ae78ff1f;
--rs-color-background-warning: #cc4d00;
--rs-color-background-warning-highlighted: #b43600;
--rs-color-background-warning-faded: #f572391f;
--rs-color-background-blur: #0c162ef2;
--rs-color-background-blur-neutral-faded: #0e1831f2;
--rs-color-background-blur-neutral-highlighted: #131d36f2;
--rs-color-foreground-primary-faded: #75b9ff;
--rs-color-foreground-critical-faded: #ff787c;
--rs-color-foreground-positive-faded: #71d799;
--rs-color-foreground-upgrade: #cd99ff;
--rs-color-foreground-upgrade-faded: #cd99ff;
--rs-color-foreground-warning: #ff935b;
--rs-color-foreground-warning-faded: #ff935b;
--rs-color-foreground-neutral-placeholder: #657088;
--rs-color-border-upgrade: #874ce6;
--rs-color-border-upgrade-faded: #9057f14d;
--rs-color-border-warning: #cc4d00;
--rs-color-border-warning-faded: #d2520d4d;
--rs-color-border-focus: #104fc6;
--rs-color-border-focus-faded: #3978f34d;
--rs-color-background-decorative-blue: #5699ff1f;
--rs-color-foreground-decorative-blue: #75b9ff;
--rs-color-border-decorative-blue: #3978f34d;
--rs-color-background-decorative-cyan: #55abc91f;
--rs-color-foreground-decorative-cyan: #75cbea;
--rs-color-border-decorative-cyan: #328ca94d;
--rs-color-background-decorative-fuchsia: #e258f61f;
--rs-color-foreground-decorative-fuchsia: #ff7aff;
--rs-color-border-decorative-fuchsia: #c032d44d;
--rs-color-background-decorative-green: #4fb67b1f;
--rs-color-foreground-decorative-green: #71d799;
--rs-color-border-decorative-green: #2a975e4d;
--rs-color-background-decorative-grey: #9e9e9e1f;
--rs-color-foreground-decorative-grey: #bdbebe;
--rs-color-border-decorative-grey: #8080804d;
--rs-color-background-decorative-light-grey: #949dbb1f;
--rs-color-foreground-decorative-light-grey: #b3bddc;
--rs-color-border-decorative-light-grey: #767f9c4d;
--rs-color-background-decorative-orange: #f572391f;
--rs-color-foreground-decorative-orange: #ff935b;
--rs-color-border-decorative-orange: #d2520d4d;
--rs-color-background-decorative-pink: #ff56991f;
--rs-color-foreground-decorative-pink: #ff78b8;
--rs-color-border-decorative-pink: #dc307b4d;
--rs-color-background-decorative-purple: #ae78ff1f;
--rs-color-foreground-decorative-purple: #cd99ff;
--rs-color-border-decorative-purple: #9057f14d;
--rs-color-background-decorative-red: #ff555e1f;
--rs-color-foreground-decorative-red: #ff787c;
--rs-color-border-decorative-red: #e42d404d;
--rs-color-background-decorative-teal: #56b0a61f;
--rs-color-foreground-decorative-teal: #77d1c6;
--rs-color-border-decorative-teal: #3391884d;
--rs-color-background-decorative-yellow: #d18e441f;
--rs-color-foreground-decorative-yellow: #f2ae64;
--rs-color-border-decorative-yellow: #b070204d;
--rs-color-rgb-background-primary: 16, 79, 198;
--rs-color-rgb-background-primary-faded: 86, 153, 255;
--rs-color-rgb-background-primary-highlighted: 0, 56, 174;
--rs-color-rgb-border-primary: 16, 79, 198;
--rs-color-rgb-border-primary-faded: 57, 120, 243;
--rs-color-rgb-background-critical: 212, 20, 51;
--rs-color-rgb-background-critical-faded: 255, 85, 94;
--rs-color-rgb-background-critical-highlighted: 187, 0, 30;
--rs-color-rgb-border-critical: 212, 20, 51;
--rs-color-rgb-border-critical-faded: 228, 45, 64;
--rs-color-rgb-background-positive: 17, 136, 80;
--rs-color-rgb-background-positive-faded: 79, 182, 123;
--rs-color-rgb-background-positive-highlighted: 0, 115, 61;
--rs-color-rgb-border-positive: 17, 136, 80;
--rs-color-rgb-border-positive-faded: 42, 151, 94;
--rs-color-on-background-neutral: #FFFFFF;
--rs-color-rgb-background-neutral: 145, 160, 192;
--rs-color-rgb-background-neutral-faded: 145, 160, 192;
--rs-color-rgb-background-neutral-highlighted: 145, 160, 192;
--rs-color-rgb-border-neutral: 239, 255, 255;
--rs-color-rgb-border-neutral-faded: 239, 255, 255;
--rs-color-rgb-background-disabled: 145, 160, 192;
--rs-color-rgb-background-elevation-base: 12, 22, 46;
--rs-color-rgb-background-elevation-raised: 16, 27, 51;
--rs-color-rgb-background-elevation-overlay: 21, 32, 56;
--rs-color-rgb-background-page: 12, 22, 46;
--rs-color-rgb-background-page-faded: 8, 17, 41;
--rs-color-rgb-background-upgrade: 135, 76, 230;
--rs-color-rgb-background-upgrade-highlighted: 115, 51, 206;
--rs-color-rgb-background-upgrade-faded: 174, 120, 255;
--rs-color-rgb-background-warning: 204, 77, 0;
--rs-color-rgb-background-warning-highlighted: 180, 54, 0;
--rs-color-rgb-background-warning-faded: 245, 114, 57;
--rs-color-rgb-background-blur: 12, 22, 46;
--rs-color-rgb-background-blur-neutral-faded: 14, 24, 49;
--rs-color-rgb-background-blur-neutral-highlighted: 19, 29, 54;
--rs-color-rgb-border-upgrade: 135, 76, 230;
--rs-color-rgb-border-upgrade-faded: 144, 87, 241;
--rs-color-rgb-border-warning: 204, 77, 0;
--rs-color-rgb-border-warning-faded: 210, 82, 13;
--rs-color-rgb-border-focus: 16, 79, 198;
--rs-color-rgb-border-focus-faded: 57, 120, 243;
--rs-color-rgb-background-decorative-blue: 86, 153, 255;
--rs-color-rgb-border-decorative-blue: 57, 120, 243;
--rs-color-rgb-background-decorative-cyan: 85, 171, 201;
--rs-color-rgb-border-decorative-cyan: 50, 140, 169;
--rs-color-rgb-background-decorative-fuchsia: 226, 88, 246;
--rs-color-rgb-border-decorative-fuchsia: 192, 50, 212;
--rs-color-rgb-background-decorative-green: 79, 182, 123;
--rs-color-rgb-border-decorative-green: 42, 151, 94;
--rs-color-rgb-background-decorative-grey: 158, 158, 158;
--rs-color-rgb-border-decorative-grey: 128, 128, 128;
--rs-color-rgb-background-decorative-light-grey: 148, 157, 187;
--rs-color-rgb-border-decorative-light-grey: 118, 127, 156;
--rs-color-rgb-background-decorative-orange: 245, 114, 57;
--rs-color-rgb-border-decorative-orange: 210, 82, 13;
--rs-color-rgb-background-decorative-pink: 255, 86, 153;
--rs-color-rgb-border-decorative-pink: 220, 48, 123;
--rs-color-rgb-background-decorative-purple: 174, 120, 255;
--rs-color-rgb-border-decorative-purple: 144, 87, 241;
--rs-color-rgb-background-decorative-red: 255, 85, 94;
--rs-color-rgb-border-decorative-red: 228, 45, 64;
--rs-color-rgb-background-decorative-teal: 86, 176, 166;
--rs-color-rgb-border-decorative-teal: 51, 145, 136;
--rs-color-rgb-background-decorative-yellow: 209, 142, 68;
--rs-color-rgb-border-decorative-yellow: 176, 112, 32;
			}
		